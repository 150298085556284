<template>
  <div>
    <modal-confirm-generic
      ref="unSaveChangesModal"
      :description="$t('components/lims/modals/ModalFormCancel.text')"
      @onConfirm="onUnSaveChanges"
    />
    <role-form
      v-if="featurePermissions.length > 0"
      :featurePermissions="featurePermissions"
      :userType="userTypeId"
      :formMode="formMode"
    ></role-form>
  </div>
</template>

<script>
import { FORM_MODES } from '@/core/constants';
import RoleForm from '../Forms/RoleForm.vue';
import { ucFirst } from '@/core/helpers';
import RoleMixins from '@/pages/Account/Role/role.mixins';
import { UnSaveChangesMixins } from '@/core/mixins';

export default {
  name: 'LimsRoleAdd',
  mixins: [UnSaveChangesMixins, RoleMixins],
  components: {
    RoleForm,
  },
  async created() {
    this.featurePermissions = await this.$loadFeaturePermissions({
      userTypeId: this.USER_TYPES()[ucFirst(this.userType)],
    });
  },
  props: {
    userType: {
      type: String,
      require: true,
    },
  },
  computed: {
    userTypeId() {
      return this.USER_TYPES()[ucFirst(this.userType)];
    },
    formMode() {
      return FORM_MODES.ADD;
    },
  },
  data() {
    return {
      featurePermissions: [],
    };
  },
};
</script>

<style lang="scss" scoped></style>
